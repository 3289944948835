import { get, post } from './request'

export default {
  loadAbilities: (success, failure) => get(
    '/api/abilities',
    response => success(response),
    error => failure(error)
  ),

  allowAbility: (payload, success, failure) => post(
    `/api/user/${payload.user_id}/add-ability`,
    payload,
    response => success(response),
    error => failure(error)
  ),

  removeAbility: (payload, success, failure) => post(
    `/api/user/${payload.user_id}/remove-ability`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  
  allowAllAbilities: (userId, success, failure) => post(
    `/api/user/${userId}/add-abilities`,
    {},
    response => success(response),
    error => failure(error)
  ),
  
  removeAllAbilities: (userId, success, failure) => post(
    `/api/user/${userId}/remove-abilities`,
    {},
    response => success(response),
    error => failure(error)
  ),
}