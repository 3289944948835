import { get, post } from './request'

export default {
  loadBarcodes: (path, success, failure) => {
    return get(
      path,
      response => success(response),
      error => failure(error)
    )
  },
  requestCodes: (payload, success, failure) => {
    return post(
      '/api/barcode-batch/request',
      payload,
      response => success(response),
      error => failure(error)
    )
  },

  loadBatchNumbers: (success, failure) => get(
    '/api/barcodes/batch-numbers',
    response => success(response),
    error => failure(error)
  ),

  downloadBatchCsv: (batchNumber, success, failure) => get(
    '/api/barcodes/download-batch',
    response => success(response),
    error => failure(error),
    { params: { batch: batchNumber }, responseType: 'blob' }
  )

}
