import axios from 'axios'
import store from '@/store'
import router from '@/routes'
var Qs = require('qs');
import omitBy from 'lodash/omitBy'
import momentTimezone from 'moment-timezone'
import { services } from '@/mixins/js-helpers'

let baseURL = process.env.VUE_APP_BASE_URL

export let http = axios.create({
  baseURL,
  withCredentials: true,
  paramsSerializer: function(params) {
    let query = Qs.stringify(omitBy(params, value => {
      return value === '' || value === null
    }), {
      arrayFormat: 'brackets'
    })
    return query || ''
  },
});

http.interceptors.request.use(config => {
  Object.assign(config.headers, {
    ...services.auth.authHeaders(),
    ...{
      'X-TZ': momentTimezone.tz.guess()
    }
  })
  return config
})

http.interceptors.response.use(response => {
  if(response.status === 200 || response.status === 201) {
    if(parseVersion(process.env.VUE_APP_VERSION) < parseVersion(response.headers['x-fe-version'])) {
      store.commit('global/appNeedsUpdate')
    }
    store.commit('billow/error/clear')
  }
  return response
}, error => {
  if(error.response && error.response.status === 422) store.commit('billow/error/set', error.response.data)

  if(router.app && error.response && error.response.status === 401) {

    if (!['signin', 'two-factor-challange'].includes(router.history.current.name)) {
      store.dispatch('auth/logout')
    }

    if (!['reset-password', 'forgot-password', 'signup', 'two-factor-challange'].includes(router.history.current.name)) {
      router.push({ name: 'signin' })
    }
  }
  if(error.response && error.response.status === 403) {
    router.push({ name: 'dashboard' })
  }

  if (error.response && error.response.status === 503) {
    router.push({ name: 'maintenance-mode' })
  }
  return Promise.reject(error)
});

export let get = (url, success, failure, options = {}) => {
  return http.get(url, {
    params: options.params
  })
  .then(response => success(response))
  .catch(error => failure(error))
}

export let post = (url, data, success, failure) => {
  return http.post(url, data)
    .then(response => success(response))
    .catch(error => failure(error))
}

export let put = (url, data, success, failure) => {
  return http.put(url, data)
    .then(response => success(response))
    .catch(error => failure(error))
}

export const delete_m = (url, success, failure) => {
  return http.delete(url)
      .then(response => success(response))
      .catch(error => failure(error))
}

let parseVersion = (versionString) => {
  let version = versionString.replace('.', '')
  return Number(version)
}

export default {
  get,
  post
}
