import { get, post } from './request'

export default {

  loadSubscription: (companyId, success, failure) => get(
    `/api/subscription/${companyId}`,
    success, failure
  )

}
