import { get, http, post } from './request'

export default {
  loadZonesBySite: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadZone: (zoneId, success, failure) => get(
    `/api/zone/${zoneId}`,
    response => success(response),
    error => failure(error)
  ),
  loadAssets: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  createZone: (zone, success, failure) => post(
    `/api/zone`,
    zone,
    response => success(response),
    error => failure(error)
  ),
  saveZone: (zone, success, failure) => post(
    `/api/zone/${zone.id}`,
    zone,
    response => success(response),
    error => failure(error)
  ),
  deleteZone: (zoneId, success, failure) => {
    return http.post(`/api/zone/${zoneId}/delete`)
      .then(response => success(response))
      .catch(error => failure(error))
  },
  loadOverview: (zoneId, success, failure) => {
    return get(
      `/api/zone/${zoneId}/overview`,
      response => success(response),
      error => failure(error)
    )
  },
  transferToSite: (zoneId, siteId, success, failure) => {
    return post(
      `api/zone/${zoneId}/transfer-to/site/${siteId}`,
      {},
      response => success(response),
      error => failure(error)
    )
  }
}
