import { get } from './request'

export default {
  loadRiskLevels: (path, success, failure) => {
    return get(
      '/api/risk-levels',
      response => success(response),
      error => failure(error)
    )
  },
  loadRiskLevelList: (success, failure) => {
    return get(
      '/api/risk-levels/list',
      response => success(response),
      error => failure(error)
    )
  }
}