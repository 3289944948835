import { get, post } from './request'
import { utilities } from '@/mixins/js-helpers'

export default {
  loadImport: (importId, success, failure) => get(
    `/api/imports/${importId}`,
    response => success(response),
    error => failure(error),
  ),

  importAssets: (payload, success, failure) => post(
    `/api/imports/assets/site/${payload.siteId}/asset-type/${payload.assetTypeId}`,
    utilities.formData({ file: payload.file }),
    response => success(response),
    error => failure(error),
  ),

  importUsers: (file, success, failure) => post(
    `/api/users/import/upload`,
    utilities.formData({ file }),
    response => success(response),
    error => failure(error),
  ),
}
