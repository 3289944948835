import { get, post } from './request'

export default {
  loadChecklistList: (success, failure) => get(
    `/api/checklists/list`,
    response => success(response),
    error => failure(error)
  ),
  loadChecklistSelectList: (success, failure) => get(
    `/api/checklists/select-list`,
    response => success(response),
    error => failure(error)
  ),
  searchChecklistSelectList: (search, success, failure) => get(
    `/api/checklists/search`, success, failure, { params: search }
  ),
  loadChecklists: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadChecklist: (checklistId, success, failure) => get(
    `/api/checklist/${checklistId}`,
    response => success(response),
    error => failure(error)
  ),
  loadChecklistVersions: (checklistId, success, failure) => get(
    `/api/checklist/${checklistId}/all`,
    response => success(response),
    error => failure(error)
  ),
  store: (payload, success, failure) => post(
    `/api/checklist`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  save: (payload, success, failure) => post(
    `/api/checklist/${payload.id}`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  deleteChecklist: (checklistId, success, failure) => post(
    `/api/checklist/${checklistId}/delete`,
    {},
    response => success(response),
    error => failure(error)
  ),
  duplicate: (payload, success, failure) => post(
    `/api/checklist/${payload.checklistId}/duplicate`,
    {detach: payload.detach},
    response => success(response),
    error => failure(error)
  ),
  syncAssetTypes: (checklist, success, failure) => post(
    `/api/checklist/${checklist.id}/sync-asset-types`,
    checklist,
    response => success(response),
    error => failure(error)
  )
}
