import { get, post } from './request'

export default {
  loadAssetGroups: (path, success, failure) => get(
    `/api${path}`,
    response => success(response),
    error => failure(error)
  ),
  loadAssetGroupList: (success, failure) => get(
    `/api/zone-types/list`,
    response => success(response),
    error => failure(error)
  ),
  store: (payload, success, failure) => post(
    `/api/asset-group`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  saveAssetGroup: (payload, success, failure) => post(
    `/api/asset-group/${payload.id}`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  deleteAssetGroup: (zoneTypeId, success, failure) => post(
    '/api/asset-group/'+ zoneTypeId + '/delete',
    {},
    response => success(response),
    error => failure(error)
  )
}
