import { get, post, http } from './request'

export default {
  loadRecurringJob: (recurringJobId, success, failure) => get(
    `/api/recurring/${recurringJobId}`,
    success, failure
  ),

  loadScheduledJob: ({ recurringJobId, scheduledJobId }, success, failure) => get(
    `/api/recurring/${recurringJobId}/schedule/${scheduledJobId}`,
    success, failure
  ),

  paginatedJobs: (path, success, failure) => get(
    typeof path === 'string' ? path : `/api/recurring/${path}/jobs`,
    success, failure
  ),

  paginatedSchedule: (path, success, failure) => get(
    typeof path === 'string' ? path : `/api/recurring/${path}/schedule`,
    success, failure
  ),

  start: (recurringJobId, success, failure) => post(
    `/api/recurring/${recurringJobId}/start`,
    {},
    success, failure
  ),

  pause: (recurringJobId, success, failure) => post(
    `/api/recurring/${recurringJobId}/pause`,
    {},
    success, failure
  ),

  previewRecurring: (payload, success, failure) => post(
    '/api/recurring/preview', payload, success, failure
  ),

  previewRecurringUpdate: (payload, success, failure) => post(
    `/api/recurring/${payload.id}/preview`, payload, success, failure
  ),

  storeRecurring: (job, success, failure) => post(
    '/api/recurring',
    job,
    success, failure
  ),

  save: (job, success, failure) => post(
    `/api/recurring/${job.id}`,
    job,
    success, failure
  ),

  overrideScheduledJobTechnician: ({ recurringJob, scheduledJob, technician }, success, failure) => post(
    `/api/recurring/${recurringJob}/schedule/${scheduledJob}/technician`,
    { technician },
    success, failure
  ),

  resetScheduledJobTechnician: (recurringJob, scheduledJob, success, failure) => post(
    `/api/recurring/${recurringJob}/schedule/${scheduledJob}/reset-technician`,
    {},
    success, failure
  ),

  changeScheduledJobDate: (recurringJob, { scheduledJob, date }, success, failure) => post(
    `/api/recurring/${recurringJob}/schedule/${scheduledJob}/date`,
    { date },
    success, failure
  ),

  deleteRecurring: (recurringJob, payload, success, failure) => post(
    `/api/recurring/${recurringJob}/delete`,
    payload, success, failure
  ),

  reopenSkippedScheduledJob: (recurringJob, success, failure) => post(
    `/api/recurring/${recurringJob}/reopen-skipped`,
    {}, success, failure
  ),

  deleteScheduledJob: (recurringJob, scheduledJob, success, failure) => post(
    `/api/recurring/${recurringJob}/schedule/${scheduledJob}/delete`,
    {}, success, failure
  ),
}
