import { get } from './request'

export default {
  loadSchedule: (success, failure) => {
    return get(
      '/api/schedule',
      response => success(response),
      error => failure(error)
    )
  },
}