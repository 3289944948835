import { get, post } from './request'
import { utilities } from '@/mixins/js-helpers'

export let openTicket = (payload, success, failure) => post(
  `/api/support/tickets`, utilities.formData(payload), success, failure
)

export let loadTicket = (ticket, success, failure) => get(
  `/api/support/tickets/${ticket}`, success, failure
)

export let addComment = ({ ticket, comment }, success, failure) => post(
  `/api/support/tickets/${ticket}/comment`, comment, success, failure
)

export let attachFile = ({ ticket, file }, success, failure) => post(
  `/api/support/tickets/${ticket}/file`, utilities.formData(file), success, failure
)

export let resolveTicket = (ticket, success, failure) => post(
  `/api/support/tickets/${ticket}/resolve`, {}, success, failure
)

export let closeTicket = (ticket, success, failure) => post(
  `/api/support/tickets/${ticket}/close`, {}, success, failure
)

export let loadAllUsers = (success, failure) => get(
  `/api/super/list-all-users`, success, failure
)

export let searchUsers = (search, success, failure) => get(
  `/api/super/search-users`, success, failure, { params: search }
  )

export let impersonateUser = (user, success, failure) => post(
  `/api/super/user/${user}/generate-support-token`, {}, success, failure
)
