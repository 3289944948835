import {delete_m, get, post, put} from './request'

export default {
    storeDynamicStatus: (payload, success, failure) => {
        return post(
            `/api/asset-types/${payload.asset_type_id}/dynamic-status`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    selectDynamicStatusOption: (payload, success, failure) => {
        return post(
            `/api/assets/${payload.asset_id}/select-dynamic-status`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    updateDynamicStatus: (payload, success, failure) => {
        return post(
            `/api/dynamic-statuses/${payload.id}`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    deleteDynamicStatus: (statusId, success, failure) => {
        return delete_m(
            `/api/dynamic-statuses/${statusId}`,
            response => success(response),
            error => failure(error)
        )
    },
    loadDynamicStatuses: (assetTypeId, success, failure) => {
        return get(
            `/api/asset-types/${assetTypeId}/dynamic-statuses`,
            response => success(response),
            error => failure(error)
        )
    },
    deleteDynamicStatusOption: (optionId, success, failure) => {
        return delete_m(
            `/api/dynamic-status-options/${optionId}`,
            response => success(response),
            error => failure(error)
        )
    }
}
