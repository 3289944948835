import { get, post } from './request'
import {utilities} from '@/mixins/js-helpers';

export default {
  store: (payload, success, failure) => {
    const data = utilities.formData(payload);
    return post(
        `/api/check`,
        data,
        response => success(response),
        error => failure(error)
    )
  },
  loadChecks: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadCheck: (checkId, success, failure) => {
    return get(
        `/api/check/${checkId}`,
        response => success(response),
        error => failure(error)
    )
  },
  search: (path, search, success, failure) => get(
    path,
    response => success(response),
    error => failure(error),
    {
      params: search
    }
  ),
  getChecksFromChecklist: (checklist, success, failure) => get(
    `/api/checklist/${checklist}/checks`,
    response => success(response),
    error => failure(error)
  ),
  update: (check, success, failure) => {
    const data = utilities.formData(check);
    return post(
        `/api/check/${check.id}`,
        data,
        response => success(response),
        error => failure(error)
    )
  },
  delete: (check, success, failure) => post(
    `/api/check/${check.id}/delete`,
    {},
    response => success(response),
    error => failure(error)
  ),
}