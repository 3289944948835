import {get, post} from './request'
import {utilities} from '@/mixins/js-helpers'

export default {

    load: (success, failure) => get(
        '/api/company',
        response => success(response),
        error => failure(error)
    ),

    update: (payload, success, failure) => post(
        '/api/company',
        payload,
        response => success(response),
        error => failure(error)
    ),

    uploadLogo: (file, success, failure) => {
        return post(
            `/api/company/logo`,
            utilities.formData({file}),
            response => success(response),
            error => failure(error)
        )
    },
    resetCompanyLogo: (success, failure) => {
        return post(
            `/api/company/logo/reset`,
            {},
            response => success(response),
            error => failure(error)
        )
    },

    verifyPayment: (payload, success, failure) => post(
        '/api/verify-payment',
        payload, success, failure
    ),

    verifyCard: (payload, success, failure) => post(
        '/api/verify-card',
        payload, success, failure
    ),

    submitCancellation: (payload, success, failure) => post(
        '/api/account/cancel',
        payload, success, failure
    ),

    stopCancellation: (success, failure) => post(
        '/api/account/cancel-stop',
        {}, success, failure
    ),

    loadCancellation: (success, failure) => get(
        `/api/account/cancellation`,
        success, failure
    ),

    loadBillingSummary: (success, failure) => get(
        `/api/billing-summary`,
        success, failure
    ),

    loadSubscribableNotificationTypesByUser: (userId, success, failure) => {
        return get(
            `/api/notifications/subscribable/user/${userId || ''}`,
            response => success(response),
            error => failure(error)
        )
    },

    loadSubscribableNotificationTypesByRole: (role, success, failure) => {
        return get(
            `/api/notifications/subscribable/role/${role}`,
            response => success(response),
            error => failure(error)
        )
    },

    loadCompanyBranding: (success, failure) => get(
        `/api/company/branding`, success, failure
    ),

    uploadBrandingAppLogo: (payload, success, failure) => post(
        `/api/company/branding/app-logo`, payload, success, failure
    ),

    uploadBrandingEmailLogo: (payload, success, failure) => post(
        `/api/company/branding/email-logo`, payload, success, failure
    ),

    resetBrandingAppLogo: (success, failure) => post(
        `/api/company/branding/app-logo/reset`, {}, success, failure
    ),

    resetBrandingEmailLogo: (success, failure) => post(
        `/api/company/branding/email-logo/reset`, {}, success, failure
    ),

    eftSubscriptionUpgrade: (success, failure) => post(
        `/api/eft-account-upgrade`, {}, success, failure
    ),

    saveBillingEmail: (email, success, failure) => post(
        `/api/company/billing-email`, {email}, success, failure
    ),

    getNamingConventions: (success, failure) => get(
        'api/company/naming-conventions', success, failure
    )
}
