import { get, post } from './request'

export default {
  loadRoleList: (success, failure) => {
    return get(
      '/api/roles/list',
      response => success(response),
      error => failure(error)
    )
  }
}