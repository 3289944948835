import { get, post, put } from './request'

export default {
  show: (checkId, success, failure) => {
    return get(
        `/api/check-predefined-comment/${checkId}`,
        response => success(response),
        error => failure(error)
    )
  },
  store: (predefinedComments, success, failure) => {
    return post(
        `/api/check-predefined-comment`,
        predefinedComments,
        response => success(response),
        error => failure(error)
    )
  },
  update: (predefinedComments, success, failure) => {
    return put(
        `/api/check-predefined-comment`,
        predefinedComments,
        response => success(response),
        error => failure(error)
    )
  },
  destroy: (predefinedCommentIds, success, failure) => {
    return post(
      `/api/check-predefined-comment/destroy`,
      predefinedCommentIds,
      response => success(response),
      error => failure(error)
    )
  },
}