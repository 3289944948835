import { get } from './request'

export default {
  loadAdmin: (success, failure) => {
    return get(
        '/api/dashboard/v2/admin',
        response => success(response),
        error => failure(error)
    )
  },
  loadPendingSignatures: (success, failure) => {
    return get(
        '/api/dashboard/v2/pending-signatures',
        response => success(response),
        error => failure(error)
    )
  },
  defectiveVsOkAssets: (success, failure) => {
    return get(
      '/api/dashboard/reporting/asset/defective-vs-ok',
      response => success(response),
      error => failure(error)
    )
  },
  inspectionsOverTime: (success, failure) => {
    return get(
      '/api/dashboard/reporting/inspections-overtime',
      response => success(response),
      error => failure(error)
    )
  },
  loadTopTechnicians: (success, failure) => {
    return get(
      '/api/dashboard/reporting/top-techs',
      response => success(response),
      error => failure(error)
    )
  },
  loadAssetDashboardCounts: (success, failure) => {
    return get(
        '/api/assets/asset-dashboard/counts',
        response => success(response),
        error => failure(error)
    )
  },
  loadAssetDashboardGraphs: (success, failure) => {
    return get(
        '/api/assets/asset-dashboard/graphs',
        response => success(response),
        error => failure(error)
    )
  },
  loadAssetDashboardLists: (success, failure) => {
    return get(
        '/api/assets/asset-dashboard/lists',
        response => success(response),
        error => failure(error)
    )
  },
  loadAssetDashboardInspections: (success, failure) => {
    return get(
        '/api/assets/asset-dashboard/inspections',
        response => success(response),
        error => failure(error)
    )
  },
}
