import {delete_m, get, post, put} from "@/api/request";

export default {
    resubmit: (transaction, success, failure) => {
        return put(
            `api/users/personas/transactions/${transaction}`,
            null,
            response => success(response),
            error => failure(error),
        )
    },
    loadAvailableSites: (data, success, failure) => {
        return post(
            'api/users/personas/available-sites',
            data,
            response => success(response),
            error => failure(error),
        )
    },
    loadAllSites: (success, failure) => {
        return get('api/users/personas/all-sites',
            response => success(response),
            error => failure(error),
        )
    },
    loadAvailableTechnicians: (data, success, failure) => {
        return post(
            'api/users/personas/available-technicians',
            data,
            response => success(response),
            error => failure(error),
        )
    },
    storeUserPersona: (data, success, failure) => {
        return post(
            'api/users/personas',
            data,
            response => success(response),
            error => failure(error),
        )
    },
    updateUserPersona: (data, success, failure) => {
        return put(
            `api/users/personas/${data.persona_id}`,
            data,
            response => success(response),
            error => failure(error),
        )
    },
    getUserPersona: (data, success, failure) => {
        return get(
            `api/users/personas/${data}`,
            response => success(response),
            error => failure(error),
        )
    },
    canAssignPersona: (data, success, failure) => {
        return get(
            `api/users/personas/${data.persona_id}/users/${data.user_id}/validate`,
            response => success(response),
            error => failure(error)
        )
    },
    assignPersona: (data, success, failure) => {
        return put(
            `api/users/personas/${data.persona_id}/users/${data.user_id}`,
            data,
            response => success(response),
            error => failure(error)
        )
    },
    searchPersonas: (search, success, failure) => {
        return post(
            `api/users/personas/search`,
            {search: search},
            response => success(response),
            error => failure(error),
        )
    },
    detachPersona: (user, success, failure) => {
        return put(
            `api/users/personas/detach/users/${user.id}`,
            user,
            response => success(response),
            error => failure(error)
        )
    },
    deletePersona: (personaId, success, failure) => {
        return delete_m(
            `api/users/personas/${personaId}`,
            response => success(response),
            error => failure(error)
        )
    }
}