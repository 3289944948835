import {delete_m, get, post, put} from './request'
import CustomField from "@/models/CustomField";
import CustomFieldTemplate from "@/models/CustomFieldTemplate";

export default {
    storeCustomField: (payload, success, failure) => {
        return post(
            `/api/custom-fields`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    storeCustomFieldTemplate: (payload, success, failure) => {
        return post(
            `/api/custom-field-templates`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    storePredefinedAnswer: (payload, success, failure) => {
        return post(
            `/api/custom-fields/${payload.custom_field_id}/predefined-answers`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    assignCustomFieldTemplate: (payload, success, failure) => {
        return post(
            `/api/site/${payload.siteId}/assign-template`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    assignTemplateToSites: (payload, success, failure) => {
        return post(
            `/api/custom-field-templates/${payload.templateId}/assign-template-to-sites`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    updateCustomFieldTemplate: (payload, success, failure) => {
        return put(
            `/api/custom-field-templates/${payload.id}`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    updatePredefinedAnswer: (payload, success, failure) => {
        return put(
        `/api/custom-field-predefined-answers/${payload.id}`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    updateCustomField: (payload, success, failure) => {
        return put(
        `/api/custom-fields/${payload.id}`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    updateCustomFieldOrder: (payload, success, failure) => {
        return post(
            `/api/custom-fields/reorder`,
            payload,
            response => success(response),
            error => failure(error)
        )
    },
    deletePredefinedAnswer: (answerId, success, failure) => {
        return delete_m(
            `/api/custom-field-predefined-answers/${answerId}`,
            response => success(response),
            error => failure(error)
        )
    },
    deleteCustomField: (customFieldId, success, failure) => {
        return delete_m(
            `/api/custom-fields/${customFieldId}`,
            response => success(response),
            error => failure(error)
        )
    },
    restoreDeletedTemplate: (customFieldTemplateId, success, failure) => {
        return post(
            `/api/custom-field-templates/${customFieldTemplateId}/restore`,
            customFieldTemplateId,
            response => success(response),
            error => failure(error)
        )
    },
    deleteCustomFieldTemplate: (customFieldTemplateId, success, failure) => {
        return delete_m(
            `/api/custom-field-templates/${customFieldTemplateId}`,
            response => success(response),
            error => failure(error)
        )
    },
    loadCustomField: (customFieldId, success, failure) => {
        return get(
            `/api/custom-fields/${customFieldId}`,
            response => success(response),
            error => failure(error)
        )
    },
    loadCustomFieldTemplateList: (success, failure) => {
        return get(
            `/api/custom-field-templates-list`,
            response => success(response),
            error => failure(error)
        )
    },
    loadDeletedTemplates: (success, failure) => {
        return get(
            `/api/custom-field-templates/trashed`,
            response => success(response),
            error => failure (error)
        )
    },
    loadCustomFieldTemplate: (customFieldTemplateId, success, failure) => {
        return get(
            `/api/custom-field-templates/${customFieldTemplateId}`,
            response => success(response),
            error => failure(error)
        )
    },
}
