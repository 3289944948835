import {get, post} from './request'

export default {

    getNamingConventions: (success, failure) => get(
        'api/company/naming-conventions', success, failure
    ),
    postNamingConventions: (success, failure, payload) => post(
        'api/company/naming-conventions', payload, success, failure
    )
}