import { services } from '@/mixins/js-helpers'
import { get, post } from './request'

export default {
  login: (payload, success, failure) => post(
    `/signin`,
    services.auth.passportLoginPayload(payload),
    response => success(response),
    error => failure(error)
  ),
  
  logout: (success, failure) => post(
    `/logout`,
    {},
    response => success(response),
    error => failure(error)
  ),
  
  signupEnquiry: (user, success, failure) => post(
    `/signup-enquiry`,
    user,
    response => success(response),
    error => failure(error)
  ),
  
  loadUser: (success, failure) => {
    return get(
      `/api/user`,
      response => success(response),
      error => failure(error)
    )
  },

  requestPasswordReset: (payload, success, failure) => post(
    `/security/password-reset/request`,
    payload, success, failure
  ),

  resetPassword: (payload, success, failure) => post(
    `/security/password-reset/reset`,
    payload, success, failure
  ),

  changePassword: (payload, success, failure) => post(
    `/api/user/password`,
    payload, success, failure
  ),

  firstPasswordChange: (payload, success, failure) => post(
    `/api/user/first-password-change`,
    payload, success, failure
  ),

  realignTimezone: (timezone, success, failure) => post(
    `/api/user/timezone`,
    { timezone }, success, failure
  ),

  load2FaQrCode: (success, failure) => get(
    `/api/two-factor-setup`,
    success, failure
  ),

  enableTwoFa: (success, failure) => post(
    `/api/two-factor-authentication`,
    {},
    success, failure
  ),

  twoFaChallenge: (payload, success, failure) => post('/two-factor-challenge', payload, success, failure),
  finalise2FaSetup: (payload, success, failure) => post('/api/two-factor-setup', payload, success, failure),
  confirmPassword: (payload, success, failure) => post('/api/confirm-password', payload, success, failure),
  disabled2Fa: (success, failure) => post('/api/disable-two-factor', {}, success, failure),

}
