import { get, post } from './request'

export default {
  loadJobTypes: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadJobType: (jobTypeId, success, failure) => get(
      `/api/job-types/${jobTypeId}`,
      response => success(response),
      error => failure(error)
  ),
  loadJobTypeList: (success, failure) => get(
    '/api/job-types/list',
    response => success(response),
    error => failure(error)
  ),
  searchJobTypes: (search, success, failure) => get(
    `/api/job-types/search`, success, failure, { params: search }
  ),
  searchActiveJobTypes: (search, success, failure) => get(
      `/api/job-types/active-search`, success, failure, { params: search }
  ),
  storeJobType: (jobType, success, failure) => post(
    '/api/job-type',
    jobType,
    response => success(response),
    error => failure(error)
  ),
  saveJobType: (jobType, success, failure) => post(
    `/api/job-type/${jobType.id}`,
    jobType,
    response => success(response),
    error => failure(error)
  ),
  
  delete: (jobTypeId, success, failure) => post(
    `/api/job-type/${jobTypeId}/delete`,
    {},
    response => success(response),
    error => failure(error)
  ),

  recoverDeletedJobType: (jobTypeId, success, failure) => post(
    `/api/job-type/${jobTypeId}/recover`,
    {},
    response => success(response),
    error => failure(error)
  )
}