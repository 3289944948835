import { get, post } from './request'

export default {
  loadAssetType: (assetType, success, failure) => get(
    `/api/asset-type/${assetType}`,
    response => success(response),
    error => failure(error)
  ),
  requestAssetHistoryReport: (assetTypeId, success, failure) => get(
    `/api/asset-types/${assetTypeId}/request-asset-history`,
    response => success(response),
    error => failure(error)
  ),
  loadAssetTypes: (success, failure) => get(
    `/api/asset-types`,
    response => success(response),
    error => failure(error)
  ),
  searchAssetTypes: (search, success, failure) => get(
    `/api/asset-types/search`,
    response => success(response),
    error => failure(error), 
    { params: search }
  ),
  loadAssetTypeList: (success, failure) => get(
    `/api/asset-types/list`,
    response => success(response),
    error => failure(error)
  ),
  store: (payload, success, failure) => post(
    `/api/asset-type`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  saveAssetType: (payload, success, failure) => post(
    `/api/asset-type/${payload.id}`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  delete: (assetTypeId, success, failure) => post(
    `/api/asset-type/${assetTypeId}/delete`,
    {},
    response => success(response),
    error => failure(error)
  ),
  loadOverview: (assetTypeId, success, failure) => get(
    `/api/asset-type/${assetTypeId}/overview`,
    response => success(response),
    error => failure(error)
  )
}
