import { get, post, http } from './request'
import { utilities } from '@/mixins/js-helpers'

export default {
  uploadDocument: (payload, success, failure) => post(
    `/api${payload.path}`,
    utilities.formData(payload.document),
    success, failure
  ),

  replaceDocument: (doc, success, failure) => post(
    `/api/documents/${doc.id}/replace`,
    utilities.formData(doc),
    success, failure
  ),

  deleteDocument: (documentId, success, failure) => post(
    `/api/documents/${documentId}/delete`,
    {},
    success, failure
  ),
}
