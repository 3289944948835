import { get, post, http } from './request'

export default {
  loadSensor: (sensorId, success, failure) => get(
    `/api/sensors/${sensorId}`,
    response => success(response),
    error => failure(error),
  ),
  loadReadings: (payload, success, failure) => get(
    `/api/sensors/${payload.sensorId}/readings${payload.query}`,
    response => success(response),
    error => failure(error),
  ),
  loadLocations: (payload, success, failure) => get(
    `/api/sensors/${payload.sensorId}/locations${payload.query}`,
    response => success(response),
    error => failure(error),
  ),
  updateSensorPersonalName: (payload, success, failure) => post(
    `/api/sensors/${payload.sensorId}/personal-name`,
    { personal_name: payload.personalName },
    response => success(response),
    error => failure(error),
  ),
}