import {get, post, http, delete_m} from './request'

export default {
  loadSensors: (assetId, success, failure) => get(
    `/api/assets/${assetId}/sensors`,
    response => success(response),
    error => failure(error)
  ),
  loadInspectionHistory: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadAssetHistory: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadOverview: (assetId, success, failure) => get(
    `/api/assets/${assetId}/overview`,
    response => success(response),
    error => failure(error)
  ),
  loadAssetsByZone: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadAssetsBySite: (path, success, failure) => get(
    path,
    response => success(response),
    error => failure(error)
  ),
  loadAsset: (assetId, success, failure) => get(
    `/api/asset/${assetId}`,
    response => success(response),
    error => failure(error)
  ),
  loadAssetTypeList: (success, failure) => get(
    `/api/asset-types/list`,
    response => success(response),
    error => failure(error)
  ),
  save: (payload, success, failure) => post(
    `/api/asset/${payload.id}`,
    payload,
    response => success(response),
    error => failure(error)
  ),
  loadForInspection: (assetId, success, failure) => get(
    `/api/assets/${assetId}/inspection`,
    response => success(response),
    error => failure(error)
  ),
  loadLastCheckin: (robotId, success, failure) => get(
    `/api/robot/${robotId}/last-checkin`,
    response => success(response),
    error => failure(error)
  ),
  loadSubAssets: (asset_id, success, failure) => get(
      `/api/assets/${asset_id}/sub-assets`,
      response => success(response),
      error => failure(error)
  ),
  loadAvailableSubAssets: (payload, success, failure) => post(
      `/api/assets/${payload.asset_id}/zones/${payload.zone_id}/available-sub-assets`,
      payload,
      response => success(response),
      error => failure(error)
  ),
  syncSubAssets: (payload, success, failure) => post(
      `/api/assets/${payload.assetId}/set-sub-assets`,
      payload,
      response => success(response),
      error => failure(error)
  ),
  transfer: (payload, success, failure) => post(
    `/api/assets/${payload.asset}/transfer`,
    { to_zone_id: payload.to_zone },
    response => success(response),
    error => failure(error)
  ),
  assignToAsset: (payload, success, failure) => post(
      `/api/assets/${payload.asset_id}/assign-to-asset`,
      {asset_id: payload.parent_id},
      response => success(response),
      error => failure(error)
  ),
  unassignAsset: (payload, success, failure) => delete_m(
      `/api/assets/${payload.asset_id}/unassign-from-asset`,
      response => success(response),
      error => failure(error)
  ),
  decommission: (payload, success, failure) => post(
    `/api/asset/${payload.asset}/decommission`,
    payload.reason,
    response => success(response),
    error => failure(error)
  ),
  recoverDecommission: (payload, success, failure) => post(
    `/api/asset/${payload}/recover-decommission`,
    {},
    response => success(response),
    error => failure(error)
  ),
  downloadInspectionReportByDateRange: (payload, success, failure) => {
    return http({
        method: 'get',
        url: `/api/assets/${payload.asset}/inspection/reports`,
        params: payload,
        responseType: 'blob'
      }
    ).then(response => success(response))
      .catch(error => failure(error))
  },
  downloadAssetHistory: (payload, success, failure) => {
    return http({
          method: 'get',
          url: `/api/assets/${payload.assetId}/history-download`,
          responseType: 'blob'
        }
    ).then(response => success(response))
        .catch(error => failure(error))
  }
}